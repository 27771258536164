import * as _getIntrinsic2 from "get-intrinsic";
var _getIntrinsic = _getIntrinsic2;
try {
  if ("default" in _getIntrinsic2) _getIntrinsic = _getIntrinsic2.default;
} catch (e) {}
import * as _callBound2 from "call-bound";
var _callBound = _callBound2;
try {
  if ("default" in _callBound2) _callBound = _callBound2.default;
} catch (e) {}
import * as _objectInspect2 from "object-inspect";
var _objectInspect = _objectInspect2;
try {
  if ("default" in _objectInspect2) _objectInspect = _objectInspect2.default;
} catch (e) {}
import * as _sideChannelMap2 from "side-channel-map";
var _sideChannelMap = _sideChannelMap2;
try {
  if ("default" in _sideChannelMap2) _sideChannelMap = _sideChannelMap2.default;
} catch (e) {}
import * as _type2 from "es-errors/type";
var _type = _type2;
try {
  if ("default" in _type2) _type = _type2.default;
} catch (e) {}
var exports = {};
var GetIntrinsic = _getIntrinsic;
var callBound = _callBound;
var inspect = _objectInspect;
var getSideChannelMap = _sideChannelMap;
var $TypeError = _type;
var $WeakMap = GetIntrinsic("%WeakMap%", true);

/** @type {<K extends object, V>(thisArg: WeakMap<K, V>, key: K) => V} */
var $weakMapGet = callBound("WeakMap.prototype.get", true);
/** @type {<K extends object, V>(thisArg: WeakMap<K, V>, key: K, value: V) => void} */
var $weakMapSet = callBound("WeakMap.prototype.set", true);
/** @type {<K extends object, V>(thisArg: WeakMap<K, V>, key: K) => boolean} */
var $weakMapHas = callBound("WeakMap.prototype.has", true);
/** @type {<K extends object, V>(thisArg: WeakMap<K, V>, key: K) => boolean} */
var $weakMapDelete = callBound("WeakMap.prototype.delete", true);

/** @type {import('.')} */
exports = $WeakMap ? /** @type {Exclude<import('.'), false>} */function getSideChannelWeakMap() {
  /** @typedef {ReturnType<typeof getSideChannelWeakMap>} Channel */
  /** @typedef {Parameters<Channel['get']>[0]} K */
  /** @typedef {Parameters<Channel['set']>[1]} V */

  /** @type {WeakMap<K & object, V> | undefined} */var $wm;
  /** @type {Channel | undefined} */
  var $m;

  /** @type {Channel} */
  var channel = {
    assert: function (key) {
      if (!channel.has(key)) {
        throw new $TypeError("Side channel does not contain " + inspect(key));
      }
    },
    "delete": function (key) {
      if ($WeakMap && key && (typeof key === "object" || typeof key === "function")) {
        if ($wm) {
          return $weakMapDelete($wm, key);
        }
      } else if (getSideChannelMap) {
        if ($m) {
          return $m["delete"](key);
        }
      }
      return false;
    },
    get: function (key) {
      if ($WeakMap && key && (typeof key === "object" || typeof key === "function")) {
        if ($wm) {
          return $weakMapGet($wm, key);
        }
      }
      return $m && $m.get(key);
    },
    has: function (key) {
      if ($WeakMap && key && (typeof key === "object" || typeof key === "function")) {
        if ($wm) {
          return $weakMapHas($wm, key);
        }
      }
      return !!$m && $m.has(key);
    },
    set: function (key, value) {
      if ($WeakMap && key && (typeof key === "object" || typeof key === "function")) {
        if (!$wm) {
          $wm = new $WeakMap();
        }
        $weakMapSet($wm, key, value);
      } else if (getSideChannelMap) {
        if (!$m) {
          $m = getSideChannelMap();
        }
        // eslint-disable-next-line no-extra-parens
        /** @type {NonNullable<typeof $m>} */
        $m.set(key, value);
      }
    }
  };

  // @ts-expect-error TODO: figure out why this is erroring
  return channel;
} : getSideChannelMap;
export default exports;